<template lang="pug">
.baseAddMoreButton
  .flex.items-center.justify-center
    slot
</template>

<script>
export default {
  name: "baseAddMoreButton",
};
</script>

<style lang="scss" scoped>
.baseAddMoreButton {
  @apply text-center border border-blue-400 rounded-lg p-2 text-blue-400 cursor-pointer transition-colors bg-blue-50;
  &:hover {
    @apply bg-blue-100;
  }
}
</style>
