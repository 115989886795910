<template lang="pug">
.baseFormHeader 
  slot
</template>

<script>
export default {
  name: "baseFormHeader",
};
</script>

<style lang="scss" scoped>
.baseFormHeader {
  @apply text-lg font-bold border-b-2 border-blue-100 border-dashed w-full block pb-2 text-gray-600 xs:text-2xl;
}
</style>
