<template lang="pug">
FormsBaseLabel(text="foto:")
.box-image(@click="showModal = !showModal")
  .image(v-if="modelValue")
    .button
      .add-button 
        BaseCustomIcon.text-blue-400(nameIcon="mdiPlusCircle", :size="72", v)
    img(:src="modelValue")

  .add-button(v-else)
    BaseCustomIcon.text-blue-400(nameIcon="mdiPlusCircle", :size="72", v)
    .text-sm.text-gray-400 {{ $t('builderPage.text_add') }}

BaseModal(:showModal="showModal")
  ModalFormProfileImage(
    v-if="showModal",
    v-on:closeModal="showModal = false",
    v-model="modelValue",
    v-on:update:modelValue="updateData"
  )
</template>

<script>
import ModalFormProfileImage from "@/components/molecules/modalFormProfileImage.vue";

export default {
  name: "baseProfileImage",
  components: {
    ModalFormProfileImage,
  },
  props: ["modelValue"],
  data: () => ({
    imageUplaoded: null,
    isDragging: false,
    zoomValue: 0,
    brightnessValue: 0,
    contrastValue: 0,
    grayScale: 100,
    showModal: false,
    showCropper: false,
  }),
  watch: {},
  computed: {},
  methods: {
    updateData(d) {
      this.$emit("update:modelValue", d);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-image {
  @apply cursor-pointer flex-grow w-36 max-h-36 flex-shrink-0 border border-dashed rounded-xl border-blue-200  overflow-hidden;

  .add-button {
    @apply h-full flex items-center justify-center flex-col transition-colors;
    &:hover {
      @apply bg-blue-50;
    }
  }

  .image {
    @apply w-full h-full relative;
    .button {
      @apply absolute w-full h-full z-10 opacity-0 transition-opacity;
      &:hover {
        @apply opacity-70;
      }
    }
  }
}
.card {
  @apply bg-white p-6 shadow-xl rounded-xl w-full max-w-xl border-2 border-blue-500 border-dashed flex flex-col;
  .header-nav {
    @apply flex justify-between items-end;

    .icon {
      @apply text-blue-500;

      &.close {
        @apply text-gray-500;
      }
    }
  }
}
</style>
