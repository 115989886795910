import axios from 'axios';

const AxiosConnectionD = () =>
  axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 360000,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

const setInterceptors = connection => {
  const token = localStorage.getItem('token_session');
  connection.interceptors.request.use(
    async config => {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  connection.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );
};

export const Api = () => {
  const connection = AxiosConnectionD();
  setInterceptors(connection);
  return connection;
};
