<template lang="pug">
.button-wrapper(@click="showFonts = !showFonts")
  .button {{ $t('builderViewer.font') }}
  XyzTransition(xyz="fade down-1 duration-1")
    .baseSelectFont(v-if="showFonts")
      .list-fonts
        .font-item(
          v-for="(font, index) in listFontsParsed",
          :key="index",
          @Click="$emit('selectedFont', font.fontName)",
          :class="[{ selected: font.selected }, font.fontName]"
        )
          p {{ font.fontName }}
</template>
<script>
export default {
  emits: ["selectedFont"],
  props: {
    font_selected: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showFonts: false,
    outsideCounterClick: 0,
    listFonts: [
      "Roboto",
      "Open",
      "Lato",
      "Montserrat",
      "Poppins",
      "Raleway",
      "Ubuntu",
      "Muli",
      "Rubik",
      "Hind",
      "Fjalla",
      "Maven",
      "Inter",
      "Cantarell",
      "Scada",
      "Playfair",
      "PT",
      "Quicksand",
      "Dosis",
      "Inconsolata",
      "Crimson",
      "Exo",
    ],
  }),
  computed: {
    listFontsParsed() {
      return this.listFonts.map((f) => {
        let selected = false;
        if (f == this.font_selected) selected = true;
        return {
          fontName: f,
          selected,
        };
      });
    },
  },
  methods: {
    setClick() {
      if (this.outsideCounterClick > 0) {
        this.showFonts = false;
      }
      this.outsideCounterClick++;
    },
  },
  watch: {
    showFonts: {
      inmediate: true,
      handler(nValue) {
        if (nValue) {
          window.addEventListener("click", this.setClick);
        } else {
          this.outsideCounterClick = 0;
          window.removeEventListener("click", this.setClick);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.baseSelectFont {
  @apply absolute rounded-md border border-blue-400 bottom-0 mb-14 w-full bg-white cursor-default overflow-hidden;
}

.list-fonts {
  @apply flex flex-col max-h-64 overflow-auto h-full;
  margin-right: 0px;

  .font-item {
    @apply flex rounded-sm cursor-pointer p-2 text-sm;

    & + .font-item {
      @apply border-t border-gray-100;
    }

    &:hover {
      @apply bg-gray-100;
    }

    &.selected {
      @apply bg-gray-200  cursor-default;
    }
  }
}
.button-wrapper {
  @apply flex bg-red-50 h-full relative;
}

.button {
  @apply relative flex-grow h-full bg-blue-50 flex items-center justify-center rounded-md cursor-pointer transition-colors text-blue-400 border border-blue-400 text-center text-sm;
  &:hover {
    @apply bg-blue-100 text-blue-500;
  }
}

@import url("https://fonts.googleapis.com/css?family=Cantarell:400,700|Crimson+Text:400,700|Dosis:400,500,700|Exo:400,500,700|Fjalla+One|Hind:400,500,700|Inconsolata:400,700|Inter:400,500,700|Lato:400,700|Maven+Pro:400,700|Montserrat:400,500,700|Muli:400,500,700|Open+Sans:400,700|PT+Sans:400,700|Playfair+Display:400,500,700|Poppins:400,500,700|Quicksand:400,500,700|Raleway:400,500,700|Roboto:400,500,700|Rubik:400,500,700|Scada:400,700|Ubuntu:400,500,700&display=swap");
.Roboto {
  line-height: auto !important;
  font-family: "Roboto", sans-serif !important;
}
.Open {
  line-height: auto !important;
  font-family: "Open Sans", sans-serif !important;
}
.Lato {
  line-height: auto !important;
  font-family: "Lato", sans-serif !important;
}
.Montserrat {
  line-height: auto !important;
  font-family: "Montserrat", sans-serif !important;
}
.Poppins {
  line-height: auto !important;
  font-family: "Poppins", sans-serif !important;
}
.Raleway {
  line-height: auto !important;
  font-family: "Raleway", sans-serif !important;
}
.Ubuntu {
  line-height: auto !important;
  font-family: "Ubuntu", sans-serif !important;
}
.Muli {
  line-height: auto !important;
  font-family: "Muli", sans-serif !important;
}
.Rubik {
  line-height: auto !important;
  font-family: "Rubik", sans-serif !important;
}
.Hind {
  line-height: auto !important;
  font-family: "Hind", sans-serif !important;
}
.Fjalla {
  line-height: auto !important;
  font-family: "Fjalla One", sans-serif !important;
}
.Maven {
  line-height: auto !important;
  font-family: "Maven Pro", sans-serif !important;
}
.Inter {
  line-height: auto !important;
  font-family: "Inter", sans-serif !important;
}
.Cantarell {
  line-height: auto !important;
  font-family: "Cantarell", sans-serif !important;
}
.Scada {
  line-height: auto !important;
  font-family: "Scada", sans-serif !important;
}
.Playfair {
  line-height: auto !important;
  font-family: "Playfair Display", serif !important;
}
.PT {
  line-height: auto !important;
  font-family: "PT Sans", sans-serif !important;
}
.Quicksand {
  line-height: auto !important;
  font-family: "Quicksand", sans-serif !important;
}
.Dosis {
  line-height: auto !important;
  font-family: "Dosis", sans-serif !important;
}
.Inconsolata {
  line-height: auto !important;
  font-family: "Inconsolata", monospace !important;
}

.Crimson {
  line-height: auto !important;
  font-family: "Crimson Text", serif !important;
}
.Exo {
  line-height: auto !important;
  font-family: "Exo", sans-serif !important;
}
</style>
