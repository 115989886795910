import baseLiteModel from './base.lite.model';

export default class LanguagesModel extends baseLiteModel {
  static entity = 'LanguagesModel';

  static fields() {
    return {
      ...super.fields(),
      section_language_level: this.attr('')
    };
  }
}
