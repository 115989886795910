import { createStore } from 'vuex';
import VuexORM from '@vuex-orm/core';

//STORES
import { DataCvBuilderStore } from './modules/data-cv-builder.store';
import { DataCvViewerStore } from './modules/data-cv-viewer.store';
import { OrderCvComponentsStore } from './modules/order-cv-components.store';
import { TemplatesStore } from './modules/templates.store';
import { ProductsStore } from './modules/products.store';
import { UserStore } from './modules/user.store';
import { UserAccountDataStore } from './modules/user-account-data.store';
import { WebOptionsStore } from './modules/web-options.store';

//MODELS
import SectionProfile from './models/profile.model';
import SectionStudies from './models/studies.model';
import SectionExperience from './models/experience.model';
import SectionCourses from './models/courses.model';
import SectionLanguages from './models/languages.model';
import SectionCompetences from './models/competences.model';
import SectionHobbie from './models/hobbie.model';
import SectionReferences from './models/references.model';
import SectionCustoms from './models/custom.model';
import SectionCustomItem from './models/custom.item.model';

const database = new VuexORM.Database();

database.register(SectionProfile);
database.register(SectionStudies);
database.register(SectionExperience);
database.register(SectionCourses);
database.register(SectionLanguages);
database.register(SectionCompetences);
database.register(SectionHobbie);
database.register(SectionReferences);
database.register(SectionCustoms);
database.register(SectionCustomItem);

export default createStore({
  plugins: [VuexORM.install(database)],
  modules: {
    DataCvViewerStore,
    DataCvBuilderStore,
    OrderCvComponentsStore,
    TemplatesStore,
    ProductsStore,
    UserStore,
    UserAccountDataStore,
    WebOptionsStore
  }
});
