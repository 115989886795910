<template lang="pug">
.input-text
  slot
</template>

<script>
export default {
  name: "baseInput",
};
</script>

<style lang="scss" scoped>
.input-text {
  @apply bg-gray-100 p-3 px-4 rounded-lg outline-none transition-colors border border-gray-200;

  &:focus {
    @apply bg-blue-50 border-blue-100;
  }

  &.error {
    @apply ring-2 ring-inset ring-red-400 border-none;
  }

  &:disabled {
    @apply text-gray-400;
  }
}
.error-text {
  @apply text-sm pl-1 pt-1 text-red-400;
}
</style>
