<template lang="pug">
label {{ text }}
</template>

<script>
export default {
  name: "baseLabel",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  @apply text-sm my-1 pl-0 text-gray-500;
}
</style>
