import baseLiteModel from './base.lite.model';

export default class HobbieModel extends baseLiteModel {
  static entity = 'HobbieModel';

  static fields() {
    return {
      ...super.fields(),
      section_description: this.attr('')
    };
  }
}
