<template lang="pug">
.basInputCheckbox
  .flex.items-center.cursor-pointer(
    @click="$emit('update:modelValue', !modelValue)"
  )
    .checkbox(:class="{ error: errorInput }")
      XyzTransition(xyz="fade up-100% ease-out-back")
        BaseCustomIcon.checkIcon(
          nameIcon="mdiCheckBold",
          v-if="modelValue",
          :size="24"
        )
    .label
      slot
</template>

<script>
export default {
  name: "baseInputCheckbox",
  props: ["modelValue", "errorInput"],
  emits: ["update:modelValue"],
};
</script>

<style lang="scss" scoped>
.basInputCheckbox {
  @apply flex items-center;
}

.checkbox {
  @apply h-6 w-6 bg-white mr-3 border-2 border-blue-400 rounded-md overflow-hidden transition-colors flex-shrink-0;

  .checkIcon {
    @apply m-0 p-0 text-blue-400;
    margin-top: -2px;
    margin-left: -2px;
  }

  &.error {
    @apply border-red-500;
  }
}

.label {
  @apply text-sm text-gray-500;

  ::v-deep(a) {
    @apply text-blue-400;

    &:hover {
      @apply underline;
    }
  }
}
</style>
