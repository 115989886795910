import baseLiteModel from './base.lite.model';

export default class CustomModel extends baseLiteModel {
  static entity = 'CustomModel';

  static fields() {
    return {
      ...super.fields()
    };
  }
}
