<template lang="pug">
picture.w-full
  //- source(
  //-   :srcset="`/theme/resumes/${id_image_formated}.webp`",
  //-   type="image/webp"
  //- )
  source(:srcset="`/theme/resumes/${id_image_formated}.png`", type="image/png")
  img(:src="`/theme/resumes/${id_image_formated}.png`")
</template>

<script>
export default {
  name: "pictureAtom",
  props: {
    id_image: Number,
  },
  setup(props) {
    return { id_image_formated: props.id_image };
  },
};
</script>

<style lang="scss" scoped></style>
