<template lang="pug">
.title
  slot
</template>

<script>
export default {
  name: "baseCardTitle",
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-gray-500 font-bold text-lg;
}
</style>
