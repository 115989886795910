const state = {
  downloads: 0,
  subscriptor: false,
  premium_ends_at: null
};

const mutations = {
  accountData(state, accountData) {
    state.downloads = accountData.downloads;
    state.subscriptor = accountData.subscriptor;
    state.premium_ends_at = accountData.premium_ends_at;
  }
};
const actions = {};

const getters = {
  hasDownloads: state => state.downloads > 0,
  getDownloads: state => state.downloads,
  isSubscriptor: state => state.subscriptor,
  isCanceledAccount: state => !!state.premium_ends_at,
  premiumEnds: state => state.premium_ends_at
};

export const UserAccountDataStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
