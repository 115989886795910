<template lang="pug">
.flex.items-center.gap-4
  BaseCustomIcon(:nameIcon="nameIcon", :size="24")
  Slider.w-full.flex-grow.mt-px(
    v-model="localValue",
    showTooltip="drag",
    @update="$emit('update:modelValue', localValue)"
  )
</template>

<script>
import Slider from "@vueform/slider";

export default {
  name: "baseInputSlider",
  emits: ["update:modelValue"],
  components: {
    Slider,
  },
  props: {
    nameIcon: {
      type: String,
      default: "mdiAccount",
    },
    modelValue: {
      type: Number,
    },
  },
  created() {
    this.localValue = this.modelValue;
  },
  data: () => ({
    localValue: 0,
  }),
};
</script>

<style lang="scss">
:root {
  --slider-connect-bg: rgba(59, 130, 246, 1);
  --slider-tooltip-bg: rgba(5, 150, 105, 1);
  --slider-handle-ring-color: rgba(29, 78, 216, 0.3);
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
