<template lang="pug">
svg-icon(type="mdi", :path="pathIcon", :size="size")
</template>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import * as listIcons from "@mdi/js";
export default {
  name: "BaseCustomIcon",
  props: {
    nameIcon: {
      type: String,
      default: "mdiAccount",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {};
  },
  components: {
    SvgIcon,
  },
  computed: {
    pathIcon() {
      return listIcons[this.nameIcon];
    },
  },
};
</script>
<style lang="scss" scoped>
</style>