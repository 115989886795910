import { UsersRepository } from '@/repository/users.repository';
import router from '@/router';
import { availableLanguages, loadLocaleMessages } from '@/languages';
const token = localStorage.getItem('token_session');
const userLanguage = localStorage.getItem('cv_locale');

const state = {
  isLogged: token ? true : false,
  user_id: null,
  user_active: false,
  user_email: null,
  user_language: userLanguage
};

const mutations = {
  loggedIn(state, { token, replace = false }) {
    localStorage.setItem('token_session', token);
    state.isLogged = true;
  },
  loggedOut(state) {
    localStorage.removeItem('token_session');
    state.isLogged = false;
    state.user_id = null;
    state.user_active = false;
    state.user_email = null;

    router.push({ name: 'Home' });
  },
  userData(state, userData) {
    state.user_id = userData._id;
    state.user_active = userData.user_active;
    state.user_email = userData.user_email;
  },
  userLanguage(state, lang) {
    state.user_language = lang.toLowerCase();
  }
};
const actions = {
  async autoLogin({ commit, dispatch }, { access_token, forceRedirect = false }) {
    commit('loggedIn', { token: access_token });
    dispatch('loadMeData');
    if (forceRedirect) router.push({ name: 'my-cvs' });
  },
  async loadMeData({ commit, dispatch }) {
    try {
      const { me, account_data } = await UsersRepository.me();
      commit('userData', me);
      commit('UserAccountDataStore/accountData', account_data, { root: true });
      dispatch('setUserLanguage', me.user_language.toLowerCase());
    } catch ({ response }) {
      console.log(response.data);
    }
  },
  setUserLanguage({ commit, dispatch }, lang) {
    if (availableLanguages.includes(lang)) {
      loadLocaleMessages(lang);
      commit('userLanguage', lang);
    }
  },
  async logout({ commit, dispatch }) {
    await dispatch('DataCvBuilderStore/deleteStoreCurriculum', null, {
      root: true
    });
    await commit(
      'UserAccountDataStore/accountData',
      {
        downloads: 0,
        subscriptor: false,
        premium_ends_at: null
      },
      { root: true }
    );
    commit('loggedOut');
  }
};
const getters = {
  getLanguange: state => state.user_language,
  getIsLogged: state => state.isLogged,
  getUserEmail: state => state.user_email
};

export const UserStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
