import { CurriculumsRepository } from '@/repository/curriculums.repository';

const state = {
  loading: false,
  cv_base64: null,
  snapshot_base64: null,
  cv_page: 1,
  cv_numPages: 1
};

const getters = {
  isLoading: state => state.loading,
  cvParsed: state => state.cv_base64,
  cvSnapshot: state => state.snapshot_base64 || localStorage.getItem('cvSnapshotBase64'),
  cvPage: state => state.cv_page,
  cvNumPages: state => state.cv_numPages
};

const mutations = {
  setCvBase64(state, payload) {
    state.cv_base64 = payload == '' ? null : payload;
  },
  setSnapShotBase64(state, payload) {
    localStorage.setItem('cvSnapshotBase64', payload);
    state.snapshot_base64 = payload == '' ? null : payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setNumpages(state, payload) {
    state.cv_numPages = payload;
  },
  setActualPage(state, payload) {
    state.cv_page = payload;
  },
  resetState(state) {
    state.loading = false;
    state.cv_base64 = null;
    state.cv_page = 1;
    state.cv_numPages = 1;
  }
};

const actions = {
  async printCv({ commit }, payload) {
    await commit('setLoading', true);
    const cvData = await CurriculumsRepository.drawCurriculum(payload);
    commit('setCvBase64', cvData.curriculum_base64);
    await commit('setLoading', false);
  },
  async nextPage({ state, commit }) {
    commit('setActualPage', state.cv_page + 1);
  },
  async prevPage({ state, commit }) {
    commit('setActualPage', state.cv_page - 1);
  }
};

export const DataCvViewerStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
