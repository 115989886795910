const state = {
  orderComponents: []
};

const mutations = {
  setOrderComponents(state, payload) {
    state.orderComponents = payload;
  },
  pushComponent(state, payload) {
    state.orderComponents.push(payload);
  },
  unsetComponent(state, payload) {
    state.orderComponents.splice(payload, 1);
  }
};
const actions = {
  changeOrderComponents({ commit }, payload) {
    commit('setOrderComponents', payload);
  },
  addComponent({ commit }, payload) {
    commit('pushComponent', payload);
  },
  removeComponent({ commit }, payload) {
    commit('unsetComponent', payload);
  }
};
const getters = {
  getOrder: state => state.orderComponents
};

export const OrderCvComponentsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
