import { TemplatesRepository } from '@/repository/templates.repository';

const state = {
  templates: []
};

const mutations = {
  updateTemplates(state, payload) {
    state.templates = payload;
  }
};
const actions = {
  async retrieveListTemplates({ commit }) {
    const listTemplatesApiResponse = await TemplatesRepository.getListTemplates();
    commit('updateTemplates', listTemplatesApiResponse);
  }
};
const getters = {
  getTemplates: state => state.templates,
  getSelectedTemplate: (state, getters, rootState, rootGetters) => {
    return state.templates.find(t => t.template_id_num == rootGetters['DataCvBuilderStore/getTemplateId']);
  }
};

export const TemplatesStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
