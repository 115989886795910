<template lang="pug">
teleport(to="body")
  XyzTransition(xyz="fade small-2 back duration-2")
    .wrapper-modal(v-if="showModal")
      .bg-modal
      .content
        slot
</template>

<script>
export default {
  name: "baseModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-modal {
  @apply fixed top-0 left-0 w-full h-full flex items-center justify-start;
  .bg-modal {
    @apply absolute w-full h-full bg-white opacity-40;
  }
  .content {
    @apply absolute w-full h-full z-10 flex items-center justify-center;
  }
}
</style>
