import baseLiteModel from './base.lite.model';

export default class ProfileModel extends baseLiteModel {
  static entity = 'ProfileModel';

  static fields() {
    return {
      ...super.fields(),
      section_description: this.attr('')
    };
  }
}
