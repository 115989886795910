import { Api } from './repository.config';

const RESOURCE = '/users';

export const UsersRepository = {
  async createAccount({ user_email, user_lang, data_cv, pdf_base64, snapshot_base64 }) {
    return Api().post(`${RESOURCE}`, {
      email: user_email,
      lang: user_lang,
      dataCv: data_cv,
      pdf_base64: pdf_base64,
      snapshot_base64: snapshot_base64
    });
  },

  async getFormPAyment() {
    return Api().post(`${RESOURCE}/me/form-payment`);
  },

  async login({ user_email, password }) {
    return Api().post(`${RESOURCE}/login`, {
      user_email,
      password
    });
  },

  async me() {
    return Api().get(`${RESOURCE}/me`);
  },

  async listCurriculums() {
    return Api().get(`${RESOURCE}/me/curriculums`);
  },

  async cloneCurriculum(curriculum_id) {
    return Api().post(`${RESOURCE}/me/curriculums/${curriculum_id}/clone`);
  },

  async deleteCurriculum(curriculum_id) {
    return Api().delete(`${RESOURCE}/me/curriculums/${curriculum_id}`);
  },

  async getCurriculum(curriculum_id) {
    return Api().get(`${RESOURCE}/me/curriculums/${curriculum_id}`);
  },

  async updateMeCurriculum(curriculum_id, data) {
    return Api().put(`${RESOURCE}/me/curriculums/${curriculum_id}`, data);
  },

  async downloadCurriculum(curriculum_id) {
    return Api().post(`${RESOURCE}/me/curriculums/${curriculum_id}`);
  },

  async sendToEmailCurriculum({ curriculum_id, email_to_send }) {
    return Api().post(`${RESOURCE}/me/curriculums/${curriculum_id}/send-to-email`, {
      email_to_send
    });
  },

  async createCurriculum() {
    return Api().post(`${RESOURCE}/me/curriculums/create`);
  },

  async setPassword({ old_psw, new_psw, repeat_psw }) {
    return Api().put(`${RESOURCE}/me/change-psw`, {
      old_psw,
      new_psw,
      repeat_psw
    });
  },

  async rememberPassword(user_email) {
    return Api().post(`${RESOURCE}/remember-psw`, {
      email: user_email
    });
  },

  async payProduct(product_id, paycomet_token) {
    return Api().post(`${RESOURCE}/me/purchase`, {
      token: paycomet_token,
      product_id
    });
  },

  async verificationPayment(payment_id) {
    return Api().get(`${RESOURCE}/me/payments/${payment_id}`);
  },

  async listMePayments() {
    return Api().get(`${RESOURCE}/me/payments`);
  },
  async unSubscribMe() {
    return Api().put(`${RESOURCE}/me/unsubscribe`);
  }
};
