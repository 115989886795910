import { Model } from '@vuex-orm/core';

const random = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export default class BaseModel extends Model {
  static entity = 'baseModel';

  // static primaryKey = ['_id'];

  static fields() {
    return {
      id: this.attr(null),
      _id: this.attr(random),
      section_position: this.number(null),
      section_title: this.attr(''),
      section_date_from: this.attr(''),
      section_date_until: this.attr(''),
      section_date_actual: this.boolean(false),
      section_description: this.attr('')
    };
  }
}
