import BaseModel from './base.model';

export default class SectionExperience extends BaseModel {
  static entity = 'ExperienceEntity';

  static fields() {
    return {
      ...super.fields(),
      section_work_bussines_name: this.attr(''),
      section_city: this.attr('')
    };
  }
}
