import { createApp } from 'vue';
import { registerComponents } from './plugins/loaderBaseComponents';
import App from './App.vue';
import router from './router';
import store from './store';
import { setConfi18n } from './languages';

import 'tailwindcss/tailwind.css';
import './assets/styles/tailwind.css';

import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core'; // Import css here if you haven't elsewhere

import ObserverFormSync from '@/plugins/observerFormSync';

//** analytics */
import VueGtag from 'vue-gtag-next';
/** adwords */
import gads from '@/plugins/gads';

const app = createApp(App);

registerComponents(app);

const navlanguage = localStorage.getItem('cv_locale') || navigator.language.slice(0, 2).toLowerCase();
store.dispatch('UserStore/setUserLanguage', navlanguage);
//listado de registro de componentes

// console.log(app._context.components);

app
  .use(setConfi18n())
  .use(store)
  .use(router)
  .use(VueAnimXyz)
  .use(ObserverFormSync)
  .use(VueGtag, {
    property: { id: 'UA-180732662-6' },
    router
  })
  .use(gads, {
    property_id: 'AW-10837201046'
  })
  .mount('#app');
