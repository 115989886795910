<template lang="pug">
.flex.flex-col.w-full
  FormsBaseLabel(:text="label")
  input.input-text(
    :value="modelValue",
    @input="$emit('update:modelValue', $event.target.value)",
    :type="inputType",
    :placeholder="placeholder",
    :class="{ error: error }",
    :disabled="'disabled' ? disabled : false"
  )
  .error-text(v-if="error") {{ error }}
</template>

<script>
export default {
  name: "baseInputText",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: "text",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./inputs-style.scss";
</style>
