import baseLiteModel from './base.lite.model';

export default class CustomItemModel extends baseLiteModel {
  static entity = 'CustomItemModel';

  static fields() {
    return {
      ...super.fields(),
      section_description: this.attr(''),
      section_custom_rel: this.attr(null)
    };
  }
}
