import { Model } from '@vuex-orm/core';

const random = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export default class baseLiteModel extends Model {
  static entity = 'baseLiteModel';

  static fields() {
    return {
      id: this.attr(null),
      _id: this.attr(random),
      section_position: this.number(null),
      section_title: this.attr('')
    };
  }
}
