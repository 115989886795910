import BaseModel from './base.model';

export default class SectionCourses extends BaseModel {
  static entity = 'CoursesEntity';

  static fields() {
    return {
      ...super.fields(),
      section_university: this.attr('')
    };
  }
}
