<template lang="pug">
.card
  FormsBaseHeader 
    .header-nav
      .flex.items-end
        BaseCustomIcon.icon(nameIcon="mdiCameraEnhanceOutline", :size="28")
        span.leading-none.pl-2 {{ $t('componentImage.title') }}
      .group-options
        BaseCustomIcon.icon.close(
          nameIcon="mdiClose",
          :size="26",
          @click="$emit('closeModal')"
        )
  .group-cropper(v-if="!!getImage")
    .wrapper-cropper(:class="{ show: showCropper }")
      Cropper.cropper(
        ref="cropper",
        :aspect-ratio="1 / 1",
        :cropBoxMovable="false",
        :cropBoxResizable="false",
        drag-mode="move",
        :view-mode="1",
        :background="false",
        :guides="false",
        :center="false",
        :autoCropArea="1",
        :zoomOnWheel="false",
        :toggleDragModeOnDblclick="false",
        :src="getImage",
        @ready="init"
      )
    .wrapper-options
      AtomsBaseInput
        .flex.flex-col.w-full.gap-4
          FormsBaseInputSlider(nameIcon="mdiMagnify", v-model="zoomValue")
          FormsBaseInputSlider(
            nameIcon="mdiBrightness5",
            v-model="brightnessValue"
          )
          FormsBaseInputSlider(
            nameIcon="mdiContrastCircle",
            v-model="contrastValue"
          )
          FormsBaseInputSlider(nameIcon="mdiPalette", v-model="grayScale")
    .wrapper-buttons.mt-4
      .flex.justify-end.gap-4
        .button-wrapper
          .button(@click="deleteImage") {{ $t('componentImage.button_delete') }}
        .button-wrapper
          .button.save(@click="getImgBase64") {{ $t('componentImage.button_save') }}
  .wrapper-add-file(
    v-else,
    @drop.prevent="dragFile",
    @dragover.prevent="draggingFile",
    @dragleave.prevent="draggingLeave",
    :class="{ isDragging: isDragging }",
    @click="$refs.inputFile.click"
  )
    BaseCustomIcon.camera(nameIcon="mdiTrayArrowDown", :size="72")
    h1 {{ $t('componentImage.title_2') }}
    .subtitle {{ $t('componentImage.description') }} <br><span>{{ $t('componentImage.formats') }}</span>.
    input.hidden(
      ref="inputFile",
      type="file",
      accept=".jpg, .jpeg, .png",
      :multiple="false",
      @change="(e) => changeInputFile(e.target.files[0])"
    )
</template>

<script>
import Cropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "modalFormProfileImage",
  components: {
    Cropper,
  },
  props: ["modelValue"],
  emits: ["update:modelValue", "closeModal"],
  data: () => ({
    imageUplaoded: null,
    isDragging: false,
    zoomValue: 0,
    brightnessValue: 0,
    contrastValue: 0,
    grayScale: 100,
    showCropper: false,
  }),
  created() { },
  watch: {
    zoomValue(n, b) {
      let factorZoom = n / 150;
      this.cropperElement.scale(factorZoom + 1);
    },
    filtersImage(filter) {
      this.cropperElement.cropper.viewBoxImage.style.filter = filter;
    },
  },
  computed: {
    getImage() {
      if (this.imageUplaoded) return this.imageUplaoded;
      if (this.modelValue) return this.modelValue;
      else return null;
    },
    cropperElement() {
      return this.$refs.cropper;
    },
    factorBrightness() {
      return this.brightnessValue / 100 + 1;
    },
    factorContrast() {
      return this.contrastValue / 100 + 1;
    },
    factorColor() {
      return 1 - this.grayScale / 100;
    },
    filtersImage() {
      return `brightness(${this.factorBrightness}) contrast(${this.factorContrast}) grayscale(${this.factorColor})`;
    },
  },
  methods: {
    async changeInputFile(file) {
      const fileBase64 = await this.toBase64(file);
      this.imageUplaoded = fileBase64;
    },
    zoom(factor) {
      this.cropperElement.zoom(factor);
    },
    init() {
      let dimensions = this.cropperElement.imageData;
      let cropboxDimension = this.cropperElement.getCropBoxData();
      let canvasDimension = this.cropperElement.getCanvasData();

      const MAX_WIDTH = 250;
      const MAX_HEIGHT = 250;
      let width = canvasDimension.width;
      let height = canvasDimension.height;

      if (width > height) {
        height = MAX_HEIGHT;
        width *= MAX_HEIGHT / height;
      } else if (width < height) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      } else {
        width = MAX_WIDTH;
        height = MAX_HEIGHT;
      }

      let left = 0;
      let top = 0;

      top = (cropboxDimension.height - MAX_HEIGHT) / 2;

      if (cropboxDimension.width > MAX_WIDTH) {
        left = (cropboxDimension.width - MAX_WIDTH) / 2;
      }

      if (width != 250 && height != 250) {
        this.cropperElement.setCropBoxData({
          top: cropboxDimension.top + top,
          left: cropboxDimension.left + left,
          width: 250,
          height: 250,
        });
      }

      this.showCropper = true;
    },

    getImgBase64() {
      const cropped = this.cropperElement.cropper.getCroppedCanvas({
        width: 200,
        height: 200,
      });
      const ctx = cropped.getContext("2d");
      ctx.filter = this.filtersImage;
      ctx.drawImage(cropped, 0, 0);
      this.$emit("update:modelValue", cropped.toDataURL());
      this.$emit("closeModal");
      this.imageUplaoded = null;
    },
    deleteImage() {
      this.$emit("update:modelValue", null);
      this.$emit("closeModal");
      this.imageUplaoded = null;
    },
    async dragFile(e) {
      const file = e.dataTransfer.files[0];
      if (["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        const fileBase64 = await this.toBase64(file);
        this.imageUplaoded = fileBase64;
      } else {
        alert("contiene Error");
      }
    },
    draggingFile() {
      this.isDragging = true;
    },
    draggingLeave() {
      this.isDragging = false;
    },
    toBase64: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),
  },
};
</script>

<style lang="scss" scoped>
.box-image {
  @apply cursor-pointer flex-grow w-36 max-h-36 flex-shrink-0 border border-dashed rounded-xl border-blue-200 flex items-center justify-center flex-col transition-colors;
  &:hover {
    @apply bg-blue-50;
  }
}
.card {
  @apply bg-white p-6 shadow-xl rounded-xl w-full max-w-xl border-2 border-blue-500 border-dashed flex flex-col;
  .header-nav {
    @apply flex justify-between items-end;

    .icon {
      @apply text-blue-500;

      &.close {
        @apply text-gray-500;
      }
    }
  }
}

.wrapper-cropper {
  @apply mt-4 h-80 flex-grow opacity-0;
  .cropper {
    @apply rounded-md overflow-hidden h-full max-h-80;
  }

  &.show {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
}

.wrapper-options {
  @apply mt-2;
}

.wrapper-add-file {
  @apply transition-colors cursor-pointer mt-2 bg-blue-50 px-4 py-8 flex items-center justify-center rounded-lg border border-blue-100 flex-col;
  .camera {
    @apply text-blue-300 mb-4 transition-colors;
  }
  h1 {
    @apply font-bold text-lg text-blue-500;
  }
  .subtitle {
    @apply font-normal text-xs text-blue-400 text-center mt-2;
    span {
      @apply font-medium underline;
    }
  }
  &:hover {
    .camera {
      @apply text-blue-400;
    }
  }

  &.isDragging {
    @apply bg-green-50 border-green-100;

    .camera {
      @apply text-green-300;
    }
  }
}

.button {
  @apply px-3 py-2 font-medium flex items-center justify-center rounded-md cursor-pointer transition-colors text-center text-base;
  &.save {
    @apply bg-green-600 text-white;
    &:hover {
      @apply bg-green-700;
    }
  }
  &:hover {
    @apply bg-gray-100;
  }
}
</style>
