import baseLiteModel from './base.lite.model';

export default class CompetencesModel extends baseLiteModel {
  static entity = 'CompetencesModel';

  static fields() {
    return {
      ...super.fields(),
      section_competence_level: this.attr('')
    };
  }
}
