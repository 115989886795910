import { Api } from './repository.config';

const RESOURCE = '/curriculums';

export const CurriculumsRepository = {
  async drawCurriculum(dataParsed) {
    return Api().post(`${RESOURCE}/draw`, {
      curriculum_data: dataParsed
    });
  }
};
