import BaseModel from './base.model';

export default class SectionStudies extends BaseModel {
  static entity = 'StudiesEntity';

  static fields() {
    return {
      ...super.fields(),
      section_center: this.attr(''),
      section_city: this.attr('')
    };
  }
}
