import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';

const routes = [
  {
    path: '/builder',
    component: () => import(/* webpackChunkName: "path_builder" */ '../components/structure/views/builder-wrapper.vue'),
    children: [
      {
        path: 'select-template',
        name: 'builder/select-template',
        component: () => import(/* webpackChunkName: "path_select_template" */ '../views/builder/step-1-select-template.vue')
      },
      {
        path: 'creator',
        name: 'builder/creator',
        component: () => import(/* webpackChunkName: "path_creator" */ '../views/builder/step-2-builder.vue')
      },
      {
        path: 'payment',
        name: 'builder/payment',
        component: () => import(/* webpackChunkName: "path_payment" */ '../views/builder/step-3-payment.vue')
      },
      {
        path: 'verification',
        name: 'builder/verification',
        component: () => import(/* webpackChunkName: "path_payment_verification" */ '../views/builder/step-4-verification-payment.vue'),
        props: route => ({ payment_id: route.query.payment_id })
      },
      {
        path: ':curriculum_id',
        component: () => import(/* webpackChunkName: "path_builder" */ '../components/structure/views/builder-wrapper-empty.vue'),
        props: true,
        meta: {
          required_auth: true
        },
        children: [
          {
            path: 'select-template',
            name: 'builder/select-template/with-id',
            component: () => import(/* webpackChunkName: "path_select_template" */ '../views/builder/step-1-select-template.vue')
          },
          {
            path: 'creator',
            name: 'builder/creator/with-id',
            component: () => import(/* webpackChunkName: "path_creator" */ '../views/builder/step-2-builder.vue')
          },
          {
            path: 'payment',
            name: 'builder/payment/with-id',
            component: () => import(/* webpackChunkName: "path_payment" */ '../views/builder/step-3-payment.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/pay',
    component: () => import(/* webpackChunkName: "public_pay" */ '../components/paycomet/page-iframe.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "public_wrapper" */ '../components/structure/views/public-wrapper.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "homepage" */ '../views/Home.vue')
      },
      {
        path: '/terms',
        name: 'Terminos',
        component: () => import(/* webpackChunkName: "basicas-terms" */ '../views/basicas/Terminos.vue')
      },
      {
        path: '/privacy',
        name: 'Privacidad',
        component: () => import(/* webpackChunkName: "basicas-privacy" */ '../views/basicas/Privacidad.vue')
      },
      {
        path: '/cookies',
        name: 'Cookies',
        component: () => import(/* webpackChunkName: "basicas-cookies" */ '../views/basicas/Cookies.vue')
      },
      {
        path: '/faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "basicas-faq" */ '../views/basicas/Faq.vue')
      },
      {
        path: '/contact',
        name: 'Contacto',
        component: () => import(/* webpackChunkName: "basicas-contact" */ '../views/basicas/Contacto.vue')
      },
      {
        path: '/cancel',
        name: 'Cancelar',
        component: () => import(/* webpackChunkName: "basicas-cancel" */ '../views/basicas/Cancelar.vue')
      },
      {
        path: '/prices',
        name: 'Precios',
        component: () => import(/* webpackChunkName: "basicas-prices" */ '../views/basicas/Prices.vue')
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        redirect: { name: 'my-cvs' }
      },
      {
        path: '/dashboard/cvs',
        name: 'my-cvs',
        component: () => import(/* webpackChunkName: "dashobard-cvs" */ '../views/dashboard/my-cvs.vue'),
        meta: {
          required_auth: true
        }
      },
      {
        path: '/dashboard/account',
        name: 'my-account',
        component: () => import(/* webpackChunkName: "dashobard-account" */ '../views/dashboard/my-account.vue'),
        meta: {
          required_auth: true
        }
      },
      {
        path: '/:catchAll(.*)',
        redirect: { name: 'Home' }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    } else {
      return {
        top: 0,
        behavior: 'smooth'
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token_session');
  if (to.meta.required_auth && !loggedIn) {
    return next('/');
  } else {
    next();
  }
});

trackRouter(router);

export default router;
