<template lang="pug">
.card
  .card-content
    slot
  .card-bottom(v-if="$slots['card-bottom']")
    slot(name="card-bottom")
</template>

<script>
export default {
  name: "baseCard",
};
</script>

<style lang="scss" scoped>
.card {
  @apply bg-white rounded-xl w-full shadow-md relative overflow-hidden border border-gray-100;

  .card-content {
    @apply p-6 py-8 xs:p-12;
  }

  .card-bottom {
    @apply bg-gray-50 border-t border-gray-200 px-12 py-4;
  }
}
</style>
