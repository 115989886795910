<template lang="pug">
.baseButton.py-3.px-8.rounded-xl.cursor-pointer.transition.transition-opacity.shadow-brand(
  class="hover:opacity-90"
) 
  slot
</template>

<script>
export default {
  name: "baseButton",
};
</script>

<style lang="scss" scoped></style>
