<template lang="pug">
routerView
</template>

<script>
import { availableLanguages } from "@/languages";
export default {
  beforeCreate() {
    this.$store.dispatch("TemplatesStore/retrieveListTemplates");
    this.$store.dispatch("ProductsStore/retrieveListproducts");
  },
  created() {
    this.$store.dispatch("UserStore/loadMeData");
  },
  methods: {
    setHeaderWebPage(title) {
      document.title = title;
    },
  },
  watch: {
    $route(to) {
      if (to.query.locale && availableLanguages.includes(to.query.locale)) {
        this.$store.dispatch('UserStore/setUserLanguage', to.query.locale)
      }
      //this.setHeaderWebPage(this.$t(`metaheader.${to.name}`));
    },
  },
};
</script>
