import baseModel from './base.model';

export default class ReferencesModel extends baseModel {
  static entity = 'ReferencesModel';

  static fields() {
    return {
      ...super.fields(),
      section_reference_contact: this.attr(''),
      section_reference_phone: this.attr(''),
      section_reference_email: this.attr('')
    };
  }
}
