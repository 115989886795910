import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

export default {
  install: app => {
    if (!app.config.globalProperties.$store) {
      throw new Error('Please initialise plugin with a Vuex store.');
    }

    const operation = new Subject();

    operation
      .pipe(
        tap(() => console.log('operation')),
        tap(() => app.config.globalProperties.$store.dispatch('DataCvBuilderStore/modStateSync', true)),
        debounceTime(500)
      )
      .subscribe(() => {
        console.log('syncronization');
        app.config.globalProperties.$store.dispatch('DataCvBuilderStore/syncCV', { draw: true });
      });

    app.provide('$ObserserFormSync', () => operation.next());
  }
};
